import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Image } from '@chakra-ui/core'

function FeaturedBanner({ children, backgroundImage }) {
  return (
    <Flex
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5) 100%), url(${backgroundImage})`}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      direction="column"
      padding={4}
      align="center"
      justify="center"
      height={{ base: '350px', lg: '550px' }}
    >
      <Image
        src="https://www.anayjose.com/images/svg/Casaadeagua_logo_landing_anayjose.svg"
        width="80%"
        maxWidth="350px"
      />
      {children}
    </Flex>
  )
}

FeaturedBanner.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
}

FeaturedBanner.defaultProps = {
  backgroundImage: '',
}

export default FeaturedBanner
