import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Heading } from '@chakra-ui/core'

function Hero({ backgroundImage, title, subtitle, cta }) {
  return (
    <Flex
      background={`linear-gradient(rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5) 100%), url(${backgroundImage})`}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundColor="gray.800"
      padding={{ base: 6, md: 10, lg: 16, xl: 24, xx: 40 }}
      height={{ base: '300px', md: '400px', lg: '500px', xl: '600px' }}
    >
      <Flex direction="column" justify="center" maxWidth={{ base: '300px', md: '420px' }}>
        <Heading
          as="h1"
          color="white"
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
          paddingBottom={2}
          lineHeight="shorter"
        >
          {title}
        </Heading>
        <Heading as="h2" color="white" fontWeight="light" fontSize={{ base: 'xl', md: '2xl' }}>
          {subtitle}
        </Heading>
        {cta}
      </Flex>
    </Flex>
  )
}

Hero.propTypes = {
  backgroundImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.node,
}

Hero.defaultProps = {
  backgroundImage: '',
  title: '',
  subtitle: '',
  cta: null,
}

export default Hero
