import React from 'react'
import { SimpleGrid } from '@chakra-ui/core'

export * from './categoryItem'

export function CategoryList({ children, ...restProps }) {
  return (
    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={10} {...restProps}>
      {children}
    </SimpleGrid>
  )
}
