import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import { Flex, PseudoBox, Heading, Text } from '@chakra-ui/core'

export function CategoryItem({ to, image, title, desc, ...restProps }) {
  return (
    <Link to={to}>
      <Flex direction="column" borderRadius="15px" width="100%" {...restProps}>
        <PseudoBox
          backgroundImage={`url(${image})`}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundColor="gray.100"
          borderRadius="15px"
          paddingBottom="65%"
          _hover={{ cursor: 'pointer' }}
        />
        <Heading as="h4" fontWeight="bold" fontSize="xl" marginTop={4}>
          {title}
        </Heading>
        <Text marginTop={2}>{desc}</Text>
      </Flex>
    </Link>
  )
}

CategoryItem.propTypes = {
  to: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
}

CategoryItem.defaultProps = {
  to: '',
  image: '',
  title: '',
  desc: '',
}
