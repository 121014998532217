import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { Heading, Text, Button, Link } from '@chakra-ui/core'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Hero from '../components/hero'
import Article from '../components/article'
import Section from '../components/section'
import FormNewsletter from '../components/formNewsletter'
import FeaturedBanner from '../components/featuredBanner'
// import { Features, FeatureItem } from '../components/features'
import { CategoryList, CategoryItem } from '../components/categoryList'

function IndexPage({ data }) {
  const { t } = useTranslation()
  const handleSubmit = async ({ email }, setSuccess) => {
    const res = await addToMailchimp(email)

    if (res.result === 'success') setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout>
      <SEO title={t('home.title')} />
      <Hero
        backgroundImage={data.hero.childImageSharp.fixed.src}
        title={t('home.hero.title')}
        subtitle={t('home.hero.subtitle')}
        cta={
          <Button
            as="a"
            href="#options"
            variantColor="teal"
            size="lg"
            width={{ sm: 'fit-content' }}
            marginTop={5}
          >
            {t('home.hero.button')}
          </Button>
        }
      />
      <Banner>
        <Link
          href="https://drive.google.com/file/d/15BvgKL2a4CB9iW1rd5x8esPG_rN19zRS"
          target="_blank"
          rel="noopener noreferrer"
          textDecoration="underline"
        >
          {t('home.covid')}
        </Link>
      </Banner>
      <Article>
        {/*<Section>
          <Features>
            <FeatureItem />
            <FeatureItem />
            <FeatureItem />
          </Features>
        </Section>*/}

        <Section id="options">
          <Heading as="h3" fontSize="2xl" marginBottom={6}>
            {t('home.options.title')}
          </Heading>
          <CategoryList>
            <CategoryItem
              to="/rooms#hiphotel"
              title={t('home.options.hiphotel.title')}
              desc={t('home.options.hiphotel.desc')}
              image={data.hiphotel.childImageSharp.fixed.src}
            />
            <CategoryItem
              to="/rooms#anayjose"
              title={t('home.options.anayjose.title')}
              desc={t('home.options.anayjose.desc')}
              image={data.anayjose.childImageSharp.fixed.src}
            />
            <CategoryItem
              to="/luxury-homes"
              title={t('home.options.luxuryHomes.title')}
              desc={t('home.options.luxuryHomes.desc')}
              image={data.luxuryhomes.childImageSharp.fixed.src}
            />
          </CategoryList>
        </Section>

        <Section maxWidth="100%" paddingX={0} paddingY={0}>
          <FeaturedBanner backgroundImage="https://www.anayjose.com/images/FOTO_banner_casadeagua_home.jpg">
            <Text fontSize="2xl" textAlign="center" maxWidth="420px" color="white" marginY={4}>
              {t('home.banner')}
            </Text>
            <Button
              as="a"
              variantColor="teal"
              width="fit-content"
              size="lg"
              href="https://www.anayjose.com/casa-de-agua.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('commons.bookNow')}
            </Button>
          </FeaturedBanner>
        </Section>

        <Section>
          <FormNewsletter
            hideOnSuccess
            title={t('home.newsletter.title')}
            subtitle={t('home.newsletter.subtitle')}
            emailPlaceholder={t('commons.emailPlaceholder')}
            buttonText={t('home.newsletter.button')}
            successMessage={t('home.newsletter.successMessage')}
            onSubmit={handleSubmit}
          />
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 520, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  query {
    hero: file(relativePath: { eq: "hero-background.png" }) {
      childImageSharp {
        fixed(width: 1270, quality: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hiphotel: file(relativePath: { eq: "featured-hiphotel.jpg" }) {
      ...fixedImage
    }
    anayjose: file(relativePath: { eq: "featured-anayjose.jpg" }) {
      ...fixedImage
    }
    luxuryhomes: file(relativePath: { eq: "featured-luxury-homes.jpg" }) {
      ...fixedImage
    }
  }
`

export default IndexPage
