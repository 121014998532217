import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { FiMail } from 'react-icons/fi'

import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/core'

function FormNewsletter({
  hideOnSuccess,
  title,
  subtitle,
  successMessage,
  emailPlaceholder,
  buttonText,
  onSubmit,
}) {
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: { email: '' },
    validate: (values) => {
      const errors = {}

      if (!values.email) {
        errors.email = 'Email is required'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Email is invalid'
      }

      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, (success) => setSuccess(success))
      setSubmitting(false)
    },
  })

  if (hideOnSuccess && success) {
    return (
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        backgroundColor="teal.50"
        padding={10}
        borderRadius="5px"
        justify="center"
      >
        <Text fontWeight="bold" fontSize="xl">
          {successMessage}
        </Text>
      </Flex>
    )
  }

  const { handleBlur, handleChange, handleSubmit, touched, errors, values, isSubmitting } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        backgroundColor="teal.50"
        padding={10}
        borderRadius="5px"
      >
        <Flex direction="column" flex="1" marginBottom={{ base: 4, lg: 0 }}>
          <Text fontWeight="bold" fontSize="md" color="teal.500">
            {subtitle}
          </Text>
          <Text fontWeight="bold" fontSize="xl">
            {title}
          </Text>
        </Flex>

        <Flex direction={{ base: 'column', lg: 'row' }} flex="1">
          <FormControl width="100%" isInvalid={errors.email && touched.email}>
            <InputGroup>
              <InputRightElement children={<FiMail />} />
              <Input
                name="email"
                placeholder={emailPlaceholder}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>

          <Button
            variantColor="teal"
            width={{ base: '100%', lg: '400px' }}
            type="submit"
            isDisabled={isSubmitting}
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

FormNewsletter.propTypes = {
  hideOnSuccess: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  buttonText: PropTypes.string,
  successMessage: PropTypes.string,
  onSuccess: PropTypes.func,
}

FormNewsletter.defaultProps = {
  hideOnSuccess: false,
  title: '',
  subtitle: '',
  emailPlaceholder: '',
  buttonText: '',
  successMessage: '',
  onSuccess: () => {},
}

export default FormNewsletter
