import React from 'react'
import { Box } from '@chakra-ui/core'

function Banner({ children }) {
  return (
    <Box bg="gray.100" display="flex" height={12} alignItems="center" justifyContent="center">
      {children}
    </Box>
  )
}

export default Banner
